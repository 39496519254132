import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Послуги | DriveRight
			</title>
			<meta name={"description"} content={"Ласкаво просимо до широкого спектру навчальних програм та курсів DriveRight, розроблених для того, щоб забезпечити вас навичками та знаннями, необхідними для того, щоб стати впевненим та відповідальним водієм. "} />
			<meta property={"og:title"} content={"Послуги | DriveRight"} />
			<meta property={"og:description"} content={"Ласкаво просимо до широкого спектру навчальних програм та курсів DriveRight, розроблених для того, щоб забезпечити вас навичками та знаннями, необхідними для того, щоб стати впевненим та відповідальним водієм. "} />
			<meta property={"og:image"} content={"https://blasterspace.live/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://blasterspace.live/images/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blasterspace.live/images/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blasterspace.live/images/favicon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://blasterspace.live/images/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://blasterspace.live/images/bg.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline1" margin="0 0 12px 0">
					Послуги
				</Text>
				<Text font="--base">
					Ласкаво просимо до широкого спектру навчальних програм та курсів DriveRight, розроблених для того, щоб забезпечити вас навичками та знаннями, необхідними для того, щоб стати впевненим та відповідальним водієм. Ми пропонуємо різноманітні варіанти для задоволення ваших потреб, незалежно від того, чи ви новачок, чи хочете покращити свої навички водіння.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				as="h2"
				margin="0px 0px 26px 0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="25%"
				md-width="100%"
			>
				Наші навчальні програми:
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="70%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Курс для початківців - Якщо ви новачок у водінні, наш курс для початківців - ідеальне місце для початку. Ми охопимо основи, починаючи від розуміння елементів керування автомобілем і закінчуючи навігацією в дорожній обстановці.
				<br />
				<br />
				Просунуте водіння - Для досвідчених водіїв, які бажають вдосконалити свої навички, наша програма просунутого водіння зосереджена на захисних техніках водіння, управлінні складними дорожніми умовами та підвищенні загальної безпеки.
				<br />
				<br />
				Підліткове водіння - Ми пропонуємо комплексне навчання водінню для підлітків, прищеплюючи відповідальні звички водіння з самого початку їхньої подорожі за кермом.
				<br />
				<br />
				Підвищення кваліфікації - Якщо ви давно не були за кермом, наш курс підвищення кваліфікації допоможе вам відновити впевненість у собі та оновити знання останніх правил дорожнього руху.
				<br />
				<br />
				Спеціалізоване навчання: Вам потрібна спеціалізована підготовка для вирішення унікальних завдань з водіння? Незалежно від того, чи це паркування, паралельне паркування або водіння по шосе, у нас є спеціальні програми для задоволення конкретних потреб.
				<br />
				<br />
				Підготовка до дорожніх тестів: Наші інструктори ретельно підготують вас до дорожнього іспиту, щоб ви почувалися впевнено і були готові до отримання водійських прав.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://blasterspace.live/images/image2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://blasterspace.live/images/image3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Готові розпочати свій шлях до досконалості водіння? Зв'яжіться з нами, щоб обговорити ваші цілі, запланувати заняття та зробити перший крок до того, щоб стати кваліфікованим і впевненим водієм. Ваш шлях до досконалості починається тут, у DriveRight.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});